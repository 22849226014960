import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="main main-content">
            <section class="home-stream-view stream-view cover-bg">
                <div class="wrapper">
			Shawn A Janas
                </div>
            </section>

            <section class="top-games">
                <div class="wrapper">
                    <div class="top-games-inner">
                        <h3>Employment History</h3>
                        <div class="top-games-list games-list">
                            
                                <article class="top-games-item game-item" app-store-url="https://itunes.apple.com/us/app/stickman-downhill/id675586452?mt=8&amp;uo=4">
                                    <a class="link" href="/web/20131106023258/http://kamcord.com/games/stickman-downhill">
                                        <span class="thumbnail">
                                            <img class="icon" src="https://web.archive.org/web/20131106023258im_/http://s3.amazonaws.com/kamcord-static/appicon/3151-appicon.jpg" data-toggle="tooltip" data-message="Kamcord" data-original-title="" title=""/>
                                            <b></b>
                                        </span>
                                        <h4>TaskPigeon</h4>
						    <div class="info">Toronto, ON</div>
                                        <span class="info">2018.11 - 2022.07</span>
                                    </a>
                                </article>
                            
                                <article class="top-games-item game-item" app-store-url="https://itunes.apple.com/us/app/trial-xtreme-3/id575570111?mt=8&amp;uo=4">
                                    <a class="link" href="/web/20131106023258/http://kamcord.com/games/trial-xtreme-3">
                                        <span class="thumbnail">
                                            <img class="icon" src="https://web.archive.org/web/20131106023258im_/http://s3.amazonaws.com/kamcord-static/appicon/1588-appicon.jpg" data-toggle="tooltip" data-message="Kamcord" data-original-title="" title=""/>
                                            <b></b>
                                        </span>
                                        <h4>Nitrio</h4>
						    <div class="info">Palo Alto, CA</div>
                                        <span class="info">2017.05 - 2018.06</span>
                                    </a>
                                </article>
                            
                                <article class="top-games-item game-item" app-store-url="https://itunes.apple.com/us/app/road-warrior-racing-multiplayer/id461481850?mt=8&amp;uo=4">
                                    <a class="link" href="/web/20131106023258/http://kamcord.com/games/road-warrior-racing-multiplayer">
                                        <span class="thumbnail">
                                            <img class="icon" src="https://web.archive.org/web/20131106023258im_/http://s3.amazonaws.com/kamcord-static/appicon/1064-appicon.jpg" data-toggle="tooltip" data-message="Kamcord" data-original-title="" title=""/>
                                            <b></b>
                                        </span>
                                        <h4>ShawnJanas.com</h4>
 						    <div class="info">Toronto, ON</div>
                                        <span class="info">2014.01 - 2017.04</span>
                                    </a>
                                </article>
                            
                                <article class="top-games-item game-item" app-store-url="https://itunes.apple.com/us/app/highway-crash-derby/id650419312?mt=8&amp;uo=4">
                                    <a class="link" href="/web/20131106023258/http://kamcord.com/games/highway-crash-derby">
                                        <span class="thumbnail">
                                            <img class="icon" src="https://web.archive.org/web/20131106023258im_/http://s3.amazonaws.com/kamcord-static/appicon/2703-appicon.jpg" data-toggle="tooltip" data-message="Kamcord" data-original-title="" title=""/>
                                            <b></b>
                                        </span>
                                        <h4>Kamcord</h4>
						    <div class="info">San Francisco, CA</div>
                                        <span class="info">2013.07 - 2013.12</span>
                                    </a>
                                </article>
                            
                                <article class="top-games-item game-item" app-store-url="https://itunes.apple.com/us/app/simplerockets/id663068211?mt=8&amp;uo=4">
                                    <a class="link" href="/web/20131106023258/http://kamcord.com/games/simplerockets">
                                        <span class="thumbnail">
                                            <img class="icon" src="https://web.archive.org/web/20131106023258im_/http://s3.amazonaws.com/kamcord-static/appicon/3877-appicon.jpg" data-toggle="tooltip" data-message="Kamcord" data-original-title="" title=""/>
                                            <b></b>
                                        </span>
                                        <h4>Engagio</h4>
						    <div class="info">Toronto, ON</div>
                                        <span class="info">2012.05 - 2012.09</span>
                                    </a>
                                </article>
                        </div>
				<div class="view-all">
                                <a target="_blank" href="https://www.linkedin.com/in/shawnjanas/">Linked In »</a>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="game-recent">
                <div class="wrapper">
                </div>
            </section>
        </div>
	  <footer>
                    <div class="wrapper">
                        I love mathematics, entrepreneurship, and computer science. I enjoy playing chess, reading, bike riding, basketball, golf, tennis, longboarding, video games and the outdoors.
                    </div>
        </footer>

		<section class="heading tagline">
                <h1>Currently working full time. I am not open to new projects at this time.</h1>
            </section>
    </div>
  );
}

export default App;
